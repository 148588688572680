<template>
  <div
    class="grid mt-3 surface-card shadow-2 p-3 border-1 border-50 border-round"
  >
    <div class="col-12"><h3>Mailing Details</h3></div>

    <DataTable
      class="col-12"
      :value="tableDataWithoutTotals"
      responsiveLayout="scroll"
      :lazy="false"
      :loading="loading"
      :paginator="false"
      :row-class="rowClass"
    >
      <template #header>
        <div class="text-right flex flex-row-reverse">
          <div class="flex w-4rem h-2rem">
            <Button
              type="button"
              class="p-button-primary mx-2"
              @click="toggle"
              icon="pi pi-eye"
              aria:haspopup="true"
              aria-controls="overlay_panel"
            />
            <OverlayPanel ref="op" appendTo="body">
              <div
                v-for="optionalColumn in allColumns"
                :key="optionalColumn.field"
                class="flex-1 px-2 py-2"
              >
                <Checkbox
                  name="city"
                  :value="optionalColumn"
                  :binary="true"
                  v-model="optionalColumn.toggle"
                />
                <label class="pl-2">{{ optionalColumn.header }}</label>
              </div>
            </OverlayPanel>
          </div>
          <div class="flex flex-column justify-content-center">
            <Dropdown
              v-model="sortBySelected"
              :options="sortByOptions"
              option-value="field"
              option-label="label"
            />
          </div>
        </div>
      </template>
      <Column field="name" header="Name" :sortable="true"></Column>
      <Column
        v-for="column in selectedColumns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :sortable="column.sortable"
      ></Column>

      <ColumnGroup type="footer">
        <Row v-if="totals" row-class="font-bold">
          <Column footer="Totals"></Column>
          <Column
            v-for="column in selectedColumns"
            :key="column.field"
            :footer="totals[column.field]"
          />
        </Row>
      </ColumnGroup>
    </DataTable>
  </div>
</template>

<script>
import MailingTable from "@/models/MailingTable";

export default {
  name: "MailingDetailTable",
  data() {
    return {
      mailingData: [],
      sortBySelected: null,
      sortByOptions: [],
      allColumns: [
        {
          key: "contacts",
          header: "Contacts",
          sortable: false,
          field: "contacts",
          toggle: true,
        },
        {
          key: "web_visits",
          header: "Web Visits",
          sortable: false,
          field: "webVisits",
          toggle: true,
        },
        {
          key: "web_visit_percentage",
          header: "Web Visit %",
          sortable: false,
          field: "webVisitPercentage",
          toggle: false,
        },
        {
          key: "web_responses",
          header: "Web Responses",
          sortable: false,
          field: "webResponses",
          toggle: true,
        },
        {
          key: "web_response_percentage",
          header: "Web Response %",
          sortable: false,
          field: "webResponsePercentage",
          toggle: true,
        },
        {
          key: "qr_visits",
          header: "QR Visits",
          sortable: false,
          field: "qrVisits",
          toggle: true,
        },
        {
          key: "qr_visit_percentage",
          header: "QR Visit %",
          sortable: false,
          field: "qrVisitPercentage",
          toggle: false,
        },
        {
          key: "qr_responses",
          header: "QR Responses",
          sortable: false,
          field: "qrResponses",
          toggle: true,
        },
        {
          key: "qr_response_percentage",
          header: "QR Response %",
          sortable: false,
          field: "qrResponsePercentage",
          toggle: true,
        },
      ],
      totalRecords: 0,
      totalRow: [],
      loading: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    sortBySelected() {
      this.loadData();
      console.log(this.totals);
    },
  },
  computed: {
    tableData() {
      if (!this.mailingData || !this.mailingData.stats) return [];

      let tableData = [];

      for (let d of this.mailingData.stats.mailed.total) {
        let td = {
          name: d.key_name,
          contacts: d.agg,
          webVisits: this.mailingData.stats.web_visits.breakdown
            .filter((data) => {
              return (
                data.key_name === d.key_name &&
                data.initial_response_channel.toLowerCase() === "web"
              );
            })
            .reduce((a, b) => a + (b["agg"] || 0), 0),
          webResponses: this.mailingData.stats.web_leads.breakdown
            .filter((data) => {
              return (
                data.key_name === d.key_name &&
                data.initial_response_channel.toLowerCase() === "web"
              );
            })
            .reduce((a, b) => a + (b["agg"] || 0), 0),
          qrVisits: this.mailingData.stats.web_visits.breakdown
            .filter((data) => {
              return (
                data.key_name === d.key_name &&
                data.initial_response_channel.toLowerCase() === "qr"
              );
            })
            .reduce((a, b) => a + (b["agg"] || 0), 0),
          qrResponses: this.mailingData.stats.web_leads.breakdown
            .filter((data) => {
              return (
                data.key_name === d.key_name &&
                data.initial_response_channel.toLowerCase() === "qr"
              );
            })
            .reduce((a, b) => a + (b["agg"] || 0), 0),
        };

        td.qrResponsePercentage = parseFloat(
          this.$filters.numberFourDecimal((td.qrResponses / td.qrVisits) * 100)
        );
        td.webResponsePercentage = parseFloat(
          this.$filters.numberFourDecimal(
            (td.webResponses / td.webVisits) * 100
          )
        );

        td.webVisitPercentage = parseFloat(
          this.$filters.numberFourDecimal((td.webVisits / td.contacts) * 100)
        );
        td.qrVisitPercentage = parseFloat(
          this.$filters.numberFourDecimal((td.qrVisits / td.contacts) * 100)
        );

        tableData.push(td);
      }

      tableData.push({
        name: "Total",
        total: true,
        contacts: tableData.reduce((a, b) => a + (b["contacts"] || 0), 0),
        webVisits: tableData.reduce((a, b) => a + (b["webVisits"] || 0), 0),
        webVisitPercentage: (
          (tableData.reduce((a, b) => a + (b["webVisits"] || 0), 0) /
            tableData.reduce((a, b) => a + (b["contacts"] || 0), 0)) *
          100
        ).toFixed(4),
        webResponses: tableData.reduce(
          (a, b) => a + (b["webResponses"] || 0),
          0
        ),
        webResponsePercentage: (
          (tableData.reduce((a, b) => a + (b["webResponses"] || 0), 0) /
            tableData.reduce((a, b) => a + (b["webVisits"] || 0), 0)) *
          100
        ).toFixed(4),
        qrVisits: tableData.reduce((a, b) => a + (b["qrVisits"] || 0), 0),
        qrVisitPercentage: (
          (tableData.reduce((a, b) => a + (b["qrVisits"] || 0), 0) /
            tableData.reduce((a, b) => a + (b["contacts"] || 0), 0)) *
          100
        ).toFixed(4),
        qrResponses: tableData.reduce((a, b) => a + (b["qrResponses"] || 0), 0),
        qrResponsePercentage: (
          (tableData.reduce((a, b) => a + (b["qrResponses"] || 0), 0) /
            tableData.reduce((a, b) => a + (b["qrVisits"] || 0), 0)) *
          100
        ).toFixed(4),
      });

      return tableData;
    },
    tableDataWithoutTotals() {
      return this.tableData.filter((x) => {
        return x.name != "Total";
      });
    },
    totals() {
      let totalarray = this.tableData.filter((x) => {
        return x.name == "Total";
      });
      return totalarray[0];
    },
    selectedColumns() {
      return this.allColumns.filter((item) => item.toggle);
    },
  },
  methods: {
    loadData() {
      this.loading = true;

      let mailingTable = this.sortBySelected
        ? MailingTable.params({ data_field: this.sortBySelected })
        : MailingTable;

      mailingTable
        .find(this.$route.params.id)
        .then((response) => {
          this.mailingData = response.data;
          this.sortByOptions = response.data.reportable_fields;
          this.sortBySelected = response.data.selected_field.field_name;
        })
        .catch()
        .then(() => (this.loading = false));
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    rowClass(data) {
      return data.total ? "font-bold" : null;
    },
  },
};
</script>
