<template>
  <TitleBar :collapseActions="false" :actions="actions">
    Mailing Overview for {{ mailingDisplay }}
  </TitleBar>

  <div class="px-5 pb-5">
    <RollingStats :mailing="mailingOverview" v-if="mailingOverview" />

    <CampaignOverviewComponent
      :campaign="mailingOverview"
      v-if="mailingOverview"
    />

    <MailingDetailTable />
  </div>
</template>

<script>
import CampaignOverview from "@/models/CampaignOverview";
import CampaignOverviewComponent from "@/components/CampaignOverview.vue";
import RollingStats from "@/components/mailingOverview/RollingStats";
import ChartLayout from "@/models/ChartLayout";
import MailingDetailTable from "@/components/mailingOverview/MailingDetailTable";
import TitleBar from "@/components/TitleBar";
import Mailing from "@/models/Mailing";
import { useAppStore } from "@/stores/app";

export default {
  components: {
    TitleBar,
    MailingDetailTable,
    RollingStats,
    CampaignOverviewComponent,
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  data() {
    return {
      mailingOverview: null,
      mailing: null,
      showDelivery: false,
      loading: false,
      actions: [
        {
          label: "View Responders",
          link: {
            name: "responders",
            params: {
              selected_mailing_id: this.$route.params.id,
            },
          },
        },
      ],
    };
  },
  async mounted() {
    await this.loadMailingOverview();
    await Mailing.where("id", this.$route.params.id)
      .get()
      .then((data) => {
        this.mailing = data.data[0];
      });
    await ChartLayout.where("campaign_id", this.campaign.id)
      .where("type", "mailing")
      .limit(1)
      .first()
      .then((data) => {
        if (data.charts) {
          this.actions.push({
            label: "Response Dashboard",
            link: {
              name: "response-dashboard",
              params: {
                mailing_id: this.$route.params.id,
              },
            },
          });
        }
      });
  },
  watch: {
    /*"$route.params"() {
      this.loadMailingOverview();

    },*/
  },
  computed: {
    campaign() {
      return this.appStore.currentCampaign;
    },
    mailingDisplay() {
      if (this.mailing.description) {
        return this.mailing.description;
      }
      return this.mailing.name;
    },
  },
  methods: {
    loadMailingOverview() {
      this.loading = true;

      CampaignOverview.where("mailing_id", this.$route.params.id)
        .find(this.campaign.id)
        .then((res) => {
          this.mailingOverview = res.data;
          if (res.data.delivered.total && res.data.delivered.total > 0) {
            this.actions.unshift({
              label: "Delivery Detail",
              link: {
                name: "delivery-detail",
                params: {
                  mailing_id: this.$route.params.id,
                },
              },
            });
          }
        })
        .catch((error) => {
          // TODO: handle different type of errors based on the err type.
          console.log(error);

          // Show error toast
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Something Went Wrong. Please Try Again",
            life: 5000,
          });
        })
        .then(() => (this.loading = false));
    },
  },
};
</script>
